<template>
  <div>
    <el-tabs v-model="activeName">
      <el-button type="warning" @click="back()" size="small">《 返回</el-button>
      <div class="feedbackbox" v-if="sight.id&&sight.feedbacks.length>0">
        <el-descriptions
          title="反馈信息"
          :column="3"
          border
          v-for="(item,index) in sight.feedbacks"
          :key="index"
        >
          <template slot="extra">
            <el-popconfirm
              title="确定完成吗？"
              @confirm="handleFeedbackFn(item.id,1)"
              style="margin-right:60px ;"
            >
              <el-button type="primary" size="small" slot="reference">已根据反馈进行了修改</el-button>
            </el-popconfirm>
            <el-popconfirm title="确定完成吗？" @confirm="handleFeedbackFn(item.id,-1)">
              <el-button type="danger" size="small" slot="reference">忽略该条反馈</el-button>
            </el-popconfirm>
          </template>
          <el-descriptions-item
            v-for="(value, key) in feedbackHead"
            :key="key"
            :label="value"
          >{{item[key]}}</el-descriptions-item>
          <el-descriptions-item v-for="(img,index) in item.imgs" :key="index" label="图片">
            <img :src="img" width="60px" alt />
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <el-tab-pane label="景区信息" name="first">
        <el-row :gutter="100" style="margin:50px 0">
          <el-col :span="12">
            <div v-if="editSightShow" style="padding: 20px">
              <el-form
                ref="editContent"
                :model="editContent"
                :rules="rules"
                label-width="100px"
                hide-required-asterisk
                size="small"
              >
                <el-form-item label="景点名称" prop="name">
                  <el-input v-model="editContent.name" maxlength="20" placeholder="必填，20字以内"></el-input>
                </el-form-item>
                <el-form-item label="景点介绍" prop="intro">
                  <el-input
                    v-model="editContent.intro"
                    :autosize="{ minRows: 5 }"
                    type="textarea"
                    placeholder="必填，10字以上"
                  ></el-input>
                </el-form-item>
                <el-form-item label="景点类别">
                  <el-select v-model="editContent.type" placeholder="请选择">
                    <el-option
                      :label="element.name"
                      :value="element.value"
                      v-for="(element,index) in sightTypes"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="editContent.state">
                    <el-option label="草稿" :value="0"></el-option>
                    <el-option label="待审核" :value="1"></el-option>
                    <el-option label="正常" :value="2"></el-option>
                    <el-option label="关闭" :value="3"></el-option>
                    <el-option label="下架" :value="4"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="星级">
                  <el-select v-model="editContent.stars">
                    <el-option label="未评级" value="未评级"></el-option>
                    <el-option label="1A" value="1A"></el-option>
                    <el-option label="2A" value="2A"></el-option>
                    <el-option label="3A" value="3A"></el-option>
                    <el-option label="4A" value="4A"></el-option>
                    <el-option label="5A" value="5A"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开放时间">
                  <el-row>
                    <el-col :span="11">
                      <el-time-select
                        placeholder="起始时间"
                        v-model="editContent.start"
                        :picker-options="{ start: '00:00',step: '00:30',end: '24:00'}"
                      ></el-time-select>
                    </el-col>
                    <el-col :span="2" style="text-align:center">-</el-col>
                    <el-col :span="11">
                      <el-time-select
                        placeholder="结束时间"
                        v-model="editContent.end"
                        :picker-options="{ start: '00:00',step: '00:30',end: '24:00',minTime: editContent.start}"
                      ></el-time-select>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="服务电话">
                  <el-input
                    v-model="editContent.phone"
                    placeholder="选填项"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  ></el-input>
                </el-form-item>
                <el-form-item label="区县">
                  <el-cascader
                    v-model="value"
                    :options="cities"
                    @change="handleChange"
                    size="small"
                    style="width:400px"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model="editContent.address" placeholder="必填"></el-input>
                </el-form-item>
                <el-form-item label="网络售票">
                  <el-select v-model="editContent.ticketSell">
                    <el-option label="开通" value="1"></el-option>
                    <el-option label="未开通" value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="政策说明">
                  <el-input
                    v-model="editContent.instruction"
                    type="textarea"
                    :autosize="{ minRows: 5 }"
                    placeholder="选填，景区政策、注意事项及防疫政策等"
                  ></el-input>
                </el-form-item>
                <el-form-item label="十万八备注">
                  <el-input v-model="editContent.remark" type="textarea" :autosize="{ minRows: 5 }"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="warning" size="small" @click="editSightShow=false">取消</el-button>
                  <el-button type="primary" size="small" @click="submitForm('editContent')">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
            <table v-else>
              <thead>
                <td width="120px">名称</td>
                <td>内容</td>
                <td>
                  <i class="el-icon-edit-outline" @click="openTextEdit"></i>
                </td>
              </thead>
              <tr>
                <td>景点ID</td>
                <td>{{sight.id}}</td>
              </tr>
              <tr>
                <td>景点名称</td>
                <td>{{sight.name}}</td>
              </tr>
              <tr>
                <td>景区介绍</td>
                <td>
                  <pre>
                    {{sight.intro}}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>分级</td>
                <td>{{sight.sort}}</td>
              </tr>
              <tr>
                <td>状态</td>
                <td>{{sight.stateName}}</td>
              </tr>
              <tr>
                <td>类别</td>
                <td>{{sight.typeName}}</td>
              </tr>
              <tr>
                <td>星级</td>
                <td>{{sight.stars}}</td>
              </tr>
              <tr>
                <td>开放时间</td>
                <td>{{sight.start}} ~ {{sight.end}}</td>
              </tr>
              <tr>
                <td>服务电话</td>
                <td>{{sight.phone}}</td>
              </tr>
              <tr>
                <td>景点地址</td>
                <td>{{sight.province}}{{sight.city}}{{sight.district}}{{sight.address}}({{ sight.adcode }})</td>
              </tr>
              <tr>
                <td>是否绑定</td>
                <td>{{sight.idSightCompany}}</td>
              </tr>
              <tr>
                <td>网络售票</td>
                <td>{{sight.ticketSell}}</td>
              </tr>
              <tr>
                <td>政策说明</td>
                <td>
                  <span v-if="sight.instruction">{{sight.instruction}}</span>
                  <span style="color:#999;font-size:13px">未填写</span>
                </td>
              </tr>
              <tr>
                <td>十万八备注</td>
                <td>
                  <span v-if="sight.instruction">{{sight.remark}}</span>
                  <span style="color:#999;font-size:13px">未填写</span>
                </td>
              </tr>
              <tr>
                <td>提交人</td>
                <td>{{ sight.idUser }} . {{ sight.userName }}</td>
              </tr>
              <tr>
                <td>提交时间</td>
                <td>{{ sight.genTime }}</td>
              </tr>
            </table>
          </el-col>
          <el-col :span="12">
            <div class="subtitle" style="margin-bottom:40px">
              <span>入口定位</span>
              <i class="el-icon-edit-outline" style="margin:0 40px" @click="getGps(-1)"></i>
            </div>
            <div class="subtitle" v-if="isImgCheck">
              <el-popconfirm title="确定完成吗？" @confirm="imgCheckedAdmin">
                <el-button type="danger" size="mini" slot="reference">用户上传了图片，检查景区图片和位置点图片</el-button>
              </el-popconfirm>
            </div>

            <div class="subtitle">
              <span style="margin-right:40px">景区图片</span>
              <i
                v-if="editImgShow&&editType=='editSight'"
                class="el-icon-circle-check"
                @click="editImgShow=false"
                style="color:darkorange"
              ></i>
              <i class="el-icon-edit-outline" @click="editImg(sight,3)" v-else></i>
            </div>
            <div v-if="editImgShow & editType=='editSight'">
              <div class="imgeditbox">
                <div v-for="(img,index) in editContent.imgs" :key="index" class="imgwrap">
                  <img :src="baseurl+img" alt class="imgwrap" @click="previewImg(baseurl+img)" />
                  <div class="imgdeletewrap" @click="deleteImgConfirm(img)">
                    <i class="el-icon-delete-solid"></i>
                  </div>
                </div>
                <div v-if="percentage>0" class="imgwrap">
                  <el-progress type="circle" :percentage="percentage"></el-progress>
                </div>
              </div>
              <div style="margin-top:20px">
                <el-upload
                  action="https://server.solar960.com/api/fileUploadWithThumb"
                  :headers="headerObj"
                  :multiple="false"
                  :limit="30"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false"
                  :on-progress="uploadProgress"
                  :on-error="uploadError"
                >
                  <el-button type="primary" size="mini" :disabled="percentage>0">点击上传图片</el-button>
                </el-upload>
              </div>
            </div>
            <div v-else>
              <img
                v-for="(item,index) in sight.imgs"
                :key="index"
                :src="baseurl+item"
                class="imgwrap"
                alt
                @click="previewImg(baseurl+item)"
              />
            </div>
            <!-- 位置点 -->

            <div class="positiontitle">位置点</div>
            <div v-for="(item,index) in positions" :key="index" class="positionbox">
              <div>
                <span class="subtitle">名称</span>
                <span>{{item.name}}</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">类别</span>
                <span>{{item.typeName}}</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">说明</span>
                <span v-if="item.intro">{{item.intro}}</span>
                <span v-else style="color:#777;font-size:13px">未填写说明</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">操作</span>
                <span
                  style="color:#33cef4;cursor:pointer;margin-right:30px"
                  @click="positionEdit(item)"
                >编辑</span>
                <span
                  style="color:#33cef4;cursor:pointer;margin-right:30px"
                  @click="getGps(index)"
                >修改定位</span>
                <span
                  style="color:red;cursor:pointer"
                  @click="deletePositionConfirm(item)"
                >删除 {{item.name}}</span>
              </div>

              <div class="subtitle">
                <span style="margin-right: 40px">信息点图片</span>
                <i
                  v-if="editImgShow & editContent.id==item.id"
                  class="el-icon-circle-check"
                  @click="editImgShow=false"
                  style="color:darkorange"
                ></i>
                <i class="el-icon-edit-outline" @click="editImg(item,1)" v-else></i>
              </div>
              <div v-if="editImgShow & editContent.id==item.id">
                <div class="imgeditbox">
                  <div v-for="(pic,index) in editContent.imgs" :key="index" class="imgwrap">
                    <img :src="baseurl+pic" alt class="imgwrap" @click="previewImg(baseurl+pic)" />
                    <div class="imgdeletewrap" @click="deleteImgConfirm(pic)">
                      <i class="el-icon-delete-solid"></i>
                    </div>
                  </div>
                  <div v-if="percentage>0" class="imgwrap">
                    <el-progress type="circle" :percentage="percentage"></el-progress>
                  </div>
                </div>
                <div style="margin-top:20px">
                  <el-upload
                    action="https://server.solar960.com/api/fileUploadWithThumb"
                    :headers="headerObj"
                    :multiple="false"
                    :limit="30"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :show-file-list="false"
                    :on-progress="uploadProgress"
                    :on-error="uploadError"
                  >
                    <el-button type="primary" size="mini" :disabled="percentage>0">点击上传图片</el-button>
                  </el-upload>
                </div>
              </div>
              <div v-else>
                <img
                  v-for="(pic,index) in item.imgs"
                  :key="index+'i'"
                  :src="baseurl+pic"
                  class="imgwrap"
                  alt
                  @click="previewImg(baseurl+pic)"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane label="地图" name="fivth">
        <mapview :positions="positions" v-if="activeName=='fivth'" />
      </el-tab-pane> -->
      <el-tab-pane label="门票" name="second">
        <h3 class="btnwrap-g center-g">{{sight.ticketSellName}}</h3>
        <div class="contentsubbox">
          <div
            v-for="(item,index) in month"
            :key="index+'m'"
            class="itembox"
            :class="[item == num ? 'numbox' : '']"
            @click="getPrice(item)"
          >{{item}}月</div>
        </div>
        <div v-for="(ticket,index) in tickets" :key="index+'a'" class="contentbox">
          <h3 class="center-g">{{ticket.name}}</h3>
          <el-row :gutter="100">
            <el-col :span="12">
              <el-table :data="ticket.rules" style="width: 100%">
                <el-table-column
                  :prop="key"
                  :label="value"
                  v-for="(value,key) in ruleHead"
                  :key="key"
                ></el-table-column>
                <el-table-column label="编辑" v-if="!sight.idSightCompany" width="180">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="ticketEdit(scope.row,ticket)" size="mini">价格</el-button>
                    <el-button type="primary" @click="ticketIntroEdit(ticket)" size="mini">介绍</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin-top: 10px;">{{ ticket.intro }}</div>
            </el-col>
            <el-col :span="12">
              <div class="contentsubbox">
                <div v-for="(item,index) in ticket.prices" :key="index+'b'" class="itembox">
                  <div>{{item.day}}</div>
                  <div style="font-weight: bold;">{{item.price}}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="实时信息" name="third">
        <div>
          <table>
            <thead>
              <td>发布人</td>
              <td>发布时间</td>
              <td>内容</td>
              <td>图片</td>
              <td>操作</td>
            </thead>
            <tr v-for="(item,index) in liveInfo" :key="index">
              <td width="10%">{{item.nickName}}</td>
              <td width="15%">{{item.genTime}}</td>
              <td width="30%">{{item.text}}</td>
              <td>
                <img
                  v-for="(img,index) in item.imgs"
                  :key="index+'a'"
                  :src="img"
                  alt
                  class="imgbox"
                  @click="previewImg(img)"
                />
              </td>
              <td width="10%">
                <el-button type="danger" size="mini" plain @click="deleteSightLiveInfo(item)">删除</el-button>
              </td>
            </tr>
          </table>
          <el-pagination
            :page-size="count"
            :current-page.sync="page"
            layout="total, prev, pager, next"
            :total="total"
            hide-on-single-page
            @current-change="handleLiveInfoChange"
          ></el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="commentCount==0?'评论':'评论('+commentCount+')'" name="fourth">
        <commentpage :custId="'sight'" @count="setCommentCount" />
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="imgForPreview" width="100%" alt />
    </el-dialog>
    <!-- 地图定位 -->
    <el-dialog :visible.sync="lnglatShow" width="80%" top="1vh">
      <latlngselect
        :lnglat="[editContent.longitude,editContent.latitude]"
        @location="setLocation"
        v-if="lnglatShow"
      />
    </el-dialog>
    <!-- 编辑信息点名称和介绍 -->
    <el-dialog
      :visible.sync="showPositionEdit"
      top="15vh"
      destroy-on-close
      title="编辑信息点的名称和介绍"
      :center="true"
    >
      <div style="width:60%;margin:30px auto">
        <el-form
          ref="editContent"
          :model="editContent"
          :rules="rules"
          label-width="100px"
          hide-required-asterisk
          size="small"
        >
          <el-form-item label="信息点名称" prop="name">
            <el-input v-model="editContent.name" maxlength="10" placeholder="必填，10字以内"></el-input>
          </el-form-item>
          <el-form-item label="类别">
            <el-select v-model="editContent.type">
              <el-option
                :label="item.name"
                :value="item.value"
                v-for="(item,index) in positionTypes"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="信息点介绍">
            <el-input
              v-model="editContent.intro"
              type="textarea"
              :autosize="{ minRows: 5 }"
              placeholder="选填"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="submitForm('editContent')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="ticketEditShow" direction="rtl" destroy-on-close size="50%">
      <div style="padding: 30px;">
        <el-form
          ref="sightSelected"
          :model="ticketSelected"
          label-width="100px"
          hide-required-asterisk
          size="small"
        >
          <el-form-item label="名称">
            <el-input v-model="ticketSelected.name" maxlength="6"></el-input>
          </el-form-item>
          <el-form-item label="价格说明">
            <el-input v-model="ticketSelected.intro" :autosize="{ minRows: 5 }" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="saveTicketIntro()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commentpage from "@/components/CommentPage";
import latlngselect from "../components/LatlngSelect";
// import mapview from "../components/MapView";
import {
  adminGetSightDetail,
  adminGetSightTickets,
  adminSaveTicketIntro,
  adminGetSightLiveInfo,
  adminDeleteSightLiveInfo,
  handleFeedback,
  adminSetTicket,
  updateSight,
  getCityList,
  deleteFile,
  updatePosition,
  adminGetSightTypes,
  imgCheckedAdmin
} from "@/api/api";

export default {
  components: {
    commentpage,
    // mapview,
    latlngselect
  },
  data() {
    return {
      activeName: "first",
      showPositionEdit: false,
      showImg: false,
      commentCount: 0,
      idSight: this.$route.query.id,
      isImgCheck: this.$route.query.img ?? false,
      imgForPreview: "",
      sight: {},
      sightTypes: [],
      positionTypes: [],
      positions: [{ imgs: [] }],
      baseurl: "https://server.solar960.com",
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      },

      tickets: [],
      num: new Date().getMonth() + 1,
      mapShow: false,
      editSightShow: false,
      editContent: {
        imgs: []
      },
      value: [],
      cities: [],
      rules: {
        name: [{ required: true, message: "请输入景区名称", trigger: "blur" }],
        intro: [
          { required: true, message: "请输入景区介绍", trigger: "blur" },
          { min: 10, message: "不能低于10字", trigger: "blur" }
        ],
        latitude: [{ required: true, message: "请选择定位", trigger: "blur" }],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        imgs: [{ required: true, message: "请上传图片", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ]
      },

      percentage: 0,
      lnglatShow: false,
      editImgNum: 0,
      editImgShow: false,

      count: 10,
      page: 1,
      total: 0,
      liveInfo: [],
      editType: false,

      ticketSelected: "",
      ticketEditShow: false,

      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      ruleHead: {
        name: "名称",
        price: "价格",
        startDate: "开始",
        endDate: "结束"
      },
      liveInfoHead: {
        idUser: "用户ID",
        avatarUrl: "头像",
        text: "内容"
      },
      feedbackHead: {
        id: "ID",
        idUser: "提交人",
        genTime: "提交时间",
        text: "内容"
      }
    };
  },
  created() {
    this.num = new Date().getMonth() + 1;
    this.getSight();
    this.getTickets();
    this.getSightLiveInfo();
    this.getSightTypes();
  },

  methods: {
    // 获取景区详情
    getSight() {
      const that = this;
      adminGetSightDetail({
        id: that.idSight
      }).then(res => {
        if (res.result) {
          that.sight = res.data.sight;
          that.positions = res.data.positions;
        }
      });
    },
    getSightTypes() {
      adminGetSightTypes().then(res => {
        if (res.result) {
          this.sightTypes = res.data.sightTypes;
          this.positionTypes = res.data.positionTypes;
        }
      });
    },
    //编辑景点信息
    openTextEdit() {
      this.editContent = { ...this.sight };
      this.editSightShow = true;
      this.editType = "editSight";
      if (this.cities.length == 0) {
        this.getCityListFn();
      }
    },
    //表单检查
    submitForm(editContent) {
      this.$refs[editContent].validate(valid => {
        if (valid) {
          if (this.editType == "editPosition") {
            this.updatePositionPcFn();
          } else {
            this.updateSightFn();
          }
        } else {
          return false;
        }
      });
    },
    updateSightFn() {
      const that = this;
      updateSight(that.editContent).then(res => {
        if (res.result) {
          this.getSight();
          this.editSightShow = false;
          this.lnglatShow = false;
          this.$message.success("修改成功");
        }
      });
    },
    //城市列表
    getCityListFn() {
      const that = this;
      getCityList({
        type: "district"
      }).then(res => {
        that.cities = res.data;
        let provinceIndex = res.data.findIndex(item => {
          return (
            item.adcode.toString().slice(0, 2) ==
            that.editContent.adcode.toString().slice(0, 2)
          );
        });
        let cityIndex = res.data[provinceIndex].children.findIndex(item => {
          return (
            item.adcode.toString().slice(0, 4) ==
            that.editContent.adcode.toString().slice(0, 4)
          );
        });
        that.value = [
          res.data[provinceIndex].value,
          res.data[provinceIndex].children[cityIndex].value,
          that.editContent.adcode
        ];
      });
    },
    // 选取城市
    handleChange(value) {
      let provinceIndex = this.cities.findIndex(item => {
        return item.adcode == value[0];
      });

      this.editContent.province = this.cities[provinceIndex].name;
      let cityIndex = this.cities[provinceIndex].children.findIndex(item => {
        return item.adcode == value[1];
      });
      this.editContent.city = this.cities[provinceIndex].children[
        cityIndex
      ].name;
      let districtIndex = this.cities[provinceIndex].children[
        cityIndex
      ].children.findIndex(item => {
        return item.adcode == value[2];
      });
      this.editContent.district = this.cities[provinceIndex].children[
        cityIndex
      ].children[districtIndex].name;
      this.editContent.adcode = value[2];
    },

    //重新定位
    getGps(index) {
      if (index == -1) {
        this.editContent = {
          id: this.sight.id,
          longitude: this.sight.longitude,
          latitude: this.sight.latitude
        };
        this.editType = "eidtSight";
      } else {
        this.editContent = {
          id: this.positions[index].id,
          longitude: this.positions[index].longitude,
          latitude: this.positions[index].latitude
        };
        this.editType = "editPosition";
      }
      this.lnglatShow = true;
    },
    // 编辑图片
    editImg(item, numImgs) {
      if (numImgs == 3) {
        this.editType = "editSight";
      } else {
        this.editType = "editPosition";
      }
      this.editContent = {
        id: item.id,
        imgs: item.imgs
      };
      this.editImgNum = numImgs;
      this.editImgShow = true;
    },
    //定位获取GPS
    setLocation(e) {
      this.editContent.longitude = e[0];
      this.editContent.latitude = e[1];
      if (this.editType == "editPosition") {
        this.updatePositionPcFn();
      } else {
        this.updateSightFn();
      }
    },
    // 打开信息点编辑
    positionEdit(item) {
      this.editContent = {
        id: item.id,
        name: item.name,
        intro: item.intro ?? "",
        type: item.type
      };
      console.log(this.editContent, "11111111111");
      this.editType = "editPosition";
      this.showPositionEdit = true;
    },
    // 更新信息点
    updatePositionPcFn() {
      const that = this;
      updatePosition(that.editContent).then(res => {
        if (res.result) {
          that.lnglatShow = false;
          that.showPositionEdit = false;
          this.$message.success("修改成功");
          that.getSight();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deletePositionConfirm(item) {
      const that = this;
      this.$confirm("确认删除" + item.name + "?", {
        type: "warning"
      })
        .then(() => {
          that.editContent = {
            id: item.id,
            delete: true
          };
          that.updatePositionPcFn();
        })
        .catch(() => {});
    },

    // 获取门票信息
    getTickets() {
      const that = this;
      adminGetSightTickets({
        id: that.idSight,
        num: that.num
      }).then(res => {
        if (res.result) {
          that.tickets = res.data.tickets;
        }
      });
    },

    // 获取实时信息
    getSightLiveInfo() {
      const that = this;
      adminGetSightLiveInfo({
        id: that.idSight,
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.liveInfo = res.data.liveInfo;
          that.total = res.data.page;
        }
      });
    },

    //修改门票价格
    ticketEdit(id) {
      const that = this;
      this.$prompt("请输入价格", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d+$|^\d+[.]?\d+$/,
        inputErrorMessage: "不能为空",
        closeOnClickModal: false,
        type: "warning"
      })
        .then(({ value }) => {
          adminSetTicket({
            id: id,
            price: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "修改成功!"
              });
              that.getTickets();
            }
          });
        })
        .catch(() => {});
    },

    ticketIntroEdit(item) {
      this.ticketEditShow = true;
      this.ticketSelected = JSON.parse(JSON.stringify(item));
    },

    saveTicketIntro() {
      const that = this;
      if (!that.ticketSelected.name) {
        that.$message({
          type: "warning",
          message: "名称不能为空"
        });
        return;
      }
      adminSaveTicketIntro({
        id: that.ticketSelected.id,
        intro: that.ticketSelected.intro,
        name: that.ticketSelected.name
      }).then(res => {
        if (res.result) {
          let index = that.tickets.findIndex(item => {
            return item.id == that.ticketSelected.id;
          });
          that.tickets[index] = that.ticketSelected;
          that.ticketEditShow = false;
        }
      });
    },

    // 删除实时信息
    deleteSightLiveInfo(id) {
      const that = this;
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          adminDeleteSightLiveInfo({
            id: id
          }).then(res => {
            if (res.result) {
              that.getSightLiveInfo();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    // 获取价格
    getPrice(num) {
      this.num = num;
      this.getTickets();
    },

    // 实时信息分页点击
    handleLiveInfoChange(val) {
      //分页的点击事件
      this.page = val;
      this.adminGetSightLiveInfo();
    },
    previewImg(item) {
      this.showImg = true;
      this.imgForPreview = item.replace(".thumb", "");
    },
    back() {
      this.$router.go(-1);
    },
    handleFeedbackFn(id, state) {
      const that = this;
      handleFeedback({
        id: id,
        state: state
      }).then(res => {
        if (res.result) {
          that.getSight();
          let getFirstAuth = this.$store.getters.getFirstAuth;
          getFirstAuth.feedback = getFirstAuth.feedback - 1;
          that.$store.dispatch("setFirstAuth", getFirstAuth);
        }
      });
    },
    deleteImgConfirm(file) {
      const that = this;
      if (this.editContent.imgs.length == this.editImgNum) {
        this.$alert("图片大于" + this.editImgNum + "张时才可以删除", {
          type: "warning",
          callBack: () => {}
        });
      } else {
        this.$confirm("确认删除？", {
          type: "warning"
        })
          .then(() => {
            that.deleteFileBizFn(file);
          })
          .catch(() => {});
      }
    },
    //删除图片
    deleteFileBizFn(file) {
      const that = this;
      file = file.replace("https://server.solar960.com", "");
      deleteFile({
        file: file
      }).then(res => {
        if (res.result) {
          let index = that.editContent.imgs.findIndex(item => {
            return item == file;
          });
          that.editContent.imgs.splice(index, 1);
          if (that.editType == "editPosition") {
            that.updatePositionPcFn();
          } else {
            that.updateSightFn();
          }
        }
      });
    },
    //上传图片成功
    handleAvatarSuccess(res) {
      if (res.result) {
        let imgUrl = res.data.files;
        this.editContent.imgs.push(imgUrl);
        if (this.editType == "editPosition") {
          this.updatePositionPcFn();
        } else {
          this.updateSightFn();
        }
      } else {
        this.$message.error(res.message);
      }
      this.percentage = 0;
    },
    //图片上传检查
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },

    //上传进度
    uploadProgress(event) {
      this.percentage = parseInt(event.percent);
    },

    //上传图片错误
    uploadError() {
      this.$message({
        message: "上传失败",
        type: "error"
      });
      this.percentage = 0;
    },

    imgCheckedAdmin() {
      const that = this;
      imgCheckedAdmin({
        id: that.idSight
      }).then(res => {
        if (res.result) {
          that.isImgCheck = false;
          that.$message.success("图片已检查");
        }
      });
    },
    setCommentCount(e) {
      this.commentCount = e;
    }
  }
};
</script>

<style scoped>
.feedbackbox {
  padding: 40px 20px;
  border: 1px solid red;
  margin: 20px 0;
  box-shadow: 0 0 5px #999;
}
.sightitem {
  margin-right: 50px;
  padding-bottom: 10px;
  text-align: center;
}
.sightitem div {
  max-width: 150px;
  word-wrap: break-word;
}
.contentbox {
  box-shadow: 0 0 10px #aaa;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
}

.contentsubbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.itembox {
  margin: 5px;
  text-align: center;
  line-height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.numbox {
  border-radius: 5px;
  background: darkorange;
  color: #fff;
}

.imgbox {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  position: relative;
}
.imgwrap {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  position: relative;
}
.subtitle {
  margin-bottom: 20px;
  font-weight: bold;
  margin-right: 30px;
}
.imglistbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.imgeditbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.imgdeletewrap {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
  background: #fff;
  padding: 2px 4px;
  border-radius: 100%;
}

.positiontitle {
  margin: 30px 0;
  padding: 20px 0;
  border-bottom: 1px dashed #999;
  font-weight: bold;
}
.positionbox {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px;
  margin: 30px 0;
}
</style>